import axios from "../../services/axiosConfig";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchVacancies = createAsyncThunk(
  "vacancies/fetchAll",
  async () => {
    const response = await axios.get("/vacancies/?limit=100&page=0");
    return response.data;
  }
);

export const addVacancy = createAsyncThunk(
  "vacancies/addContact",
  async (vacancy, { rejectWithValue }) => {
    try {
      const response = await axios.post("/vacancies", { ...vacancy });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const removeVacancy = createAsyncThunk(
  "vacancies/removeContact",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`/vacancies/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

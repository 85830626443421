import { createSlice } from "@reduxjs/toolkit";
import { fetchNews, getOneNews, addNews, removeNews } from "./newsOperations";

const newsInitialState = {
  news: [],
  pagesCount: null,
  isLoading: true,
  error: null,
};

const handlePending = (state) => {
  state.isLoading = true;
};
const handleRejected = (state, action) => {
  state.isLoading = false;
  state.error = action.payload;
};

const newsSlice = createSlice({
  name: "news",
  initialState: newsInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNews.pending, handlePending)
      .addCase(getOneNews.pending, handlePending)
      .addCase(addNews.pending, handlePending)
      .addCase(removeNews.pending, handlePending)
      .addCase(fetchNews.rejected, handleRejected)
      .addCase(getOneNews.rejected, handleRejected)
      .addCase(addNews.rejected, handleRejected)
      .addCase(removeNews.rejected, handleRejected)
      .addCase(fetchNews.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.news = action.payload.news;
        state.pagesCount = action.payload.pagesCount;
      })
      .addCase(getOneNews.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        const index = state.news.findIndex(
          (item) => item.id === action.payload.id
        );

        if (index === -1) {
          state.news.push(action.payload);
        } else {
          state.news[index] = action.payload;
        }
      })
      .addCase(addNews.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.news.push(action.payload.news);
      })
      .addCase(removeNews.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;

        const index = state.news.findIndex(
          (item) => item.id === action.payload.id
        );
        state.contacts.splice(index, 1);
      });
  },
});

export const newsReduser = newsSlice.reducer;

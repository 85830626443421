import axios from "../../services/axiosConfig";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchNews = createAsyncThunk("news/fetchAll", async (settings) => {
  const { page, category } = settings;
  const response = await axios.get(
    `/news/?limit=12&page=${page}&category=${category}`
  );
  return response.data;
});

export const getOneNews = createAsyncThunk("news/geyOneNews", async (id) => {
  const response = await axios.get(`/news/${id}`);
  return response.data;
});

export const addNews = createAsyncThunk(
  "news/addContact",
  async (news, { rejectWithValue }) => {
    try {
      const response = await axios.post("/news", { ...news });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const removeNews = createAsyncThunk(
  "news/removeContact",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`/news/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../services/axiosConfig";

const setAuthHeader = (token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

const clearAuthHeader = () => {
  axios.defaults.headers.common.Authorization = "";
};

export const login = createAsyncThunk(
  "auth/login",
  async (obj, { rejectWithValue }) => {
    try {
      const responce = await axios.post("/api/auth/login", { ...obj });
      setAuthHeader(responce.data.token);

      return responce.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const logout = createAsyncThunk(
  "auth/logout",
  async (_, { rejectWithValue }) => {
    clearAuthHeader();
    //   try {
    //     const responce = await axios.post('/users/auth/logout');
    //
    //     return responce.data;
    //   } catch (error) {
    //     rejectWithValue(error);
    //   }
  }
);

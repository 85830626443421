import { createSlice } from "@reduxjs/toolkit";

const projectsInitialState = [
  {
    id: 1,
    headerUa: "єОздоровлення",
    headerEn: "eOzdorovlennya",
    shortHeaderUA: "єОздоровлення",
    shortHeaderEN: "eOzdorovlennya",
    describeUa:
      "Проєкт спрямований на забезпечення доступу дітей з інвалідністю, що виховуються в малозабезпечених сім'ях та дітей з багатодітних малозабезпечених сімей, в тому числі з числа внутрішньопереміщених осіб, до якісних оздоровчих послуг за рахунок державного бюджету.",
    describeEn:
      "The project is aimed at providing access to quality health and wellness services for children with disabilities from low-income families and children from large low-income families, including internally displaced persons, funded by the state budget.",
    goalUa:
      "Забезпечити дітей, які потребують особливої соціальної уваги та підтримки, можливістю оздоровлення та відпочинку, за рахунок держави.",
    goalEn:
      "Provide children in need of special social attention and support with opportunities for rehabilitation and recreation at the expense of the state.",
    aboutProjectUa: [
      "Програмне забезпечення, розроблене ІОЦ Мінсоцполітики, через застосунок Дія або ПриватБанк автоматично повідомляє батьків про можливість отримати фінансову підтримку для відпочинку дитини та надає детальну інформацію про оздоровчі заклади. Батьки можуть самостійно обирати заклад і подавати заявки через сайт. Після перевірки держава перераховує кошти на спеціальну банківську картку для оплати послуг. ",
      "За минулий рік за допомогою цієї програми мали змогу оздоровитися майже 2 тисячі дітей, отже беззаперечно проєкт сприяє поліпшенню фізичного та психологічного стану дітей, їхній соціальній адаптації та здоров'ю.",
    ],
    aboutProjectEn: [
      `The software developed by the State Enterprise "Information and Computer Center of the Ministry of Social Policy of Ukraine" automatically notifies parents through the Diia or PrivatBank app about the possibility of receiving financial support for their child's vacation and provides detailed information about health facilities. Parents can choose a facility and apply online. After verification, the state transfers funds to a special bank card to pay for the services. `,
      "Last year, almost 2,000 children were able to improve their health with the help of this program, so the project undoubtedly contributes to improving the physical and psychological condition of children, their social adaptation and health.",
    ],
    statistic: [],
    link: "https://rstmo.ioc.gov.ua/mop/e-health",
    imgName: "єОздоровлення",
    video: "",
  },
  {
    id: 2,
    headerUa: "Єдина інформаційна система соціальної сфери",
    headerEn: "Unified Information System of the Social Sphere",
    shortHeaderUA: "ЄІССС",
    shortHeaderEN: "UISSS",
    describeUa:
      "Канал, що з'єднує всі установи, які забезпечують допомогу людям. Вона дозволяє даним пересуватися між цими установами, спрощуючи процеси та зменшуючи витрати часу на паперову роботу.",
    describeEn:
      "A channel that connects all institutions that provide assistance to people. It allows data to move between these institutions, simplifying processes and reducing time spent on paperwork.",
    goalUa:
      "Створення централізованої прозорої інформаційної платформи для оптимізації управління соціальними програмами та послугами.",
    goalEn:
      "Creating a centralized transparent information platform to optimize the management of social programs and services.",
    aboutProjectUa: [
      "Централізована платформа, створена для оптимізації управління соціальними програмами та послугами в Україні. Вона об'єднує різні інституції соціальної сфери в єдине інформаційне середовище, забезпечуючи централізовану обробку даних і уніфікацію технологічних процесів. Система включає такі підсистеми, як Єдиний соціальний реєстр, Електронний бюджет, Соціальне казначейство, та інші, що сприяють автоматизації соціальної підтримки, зменшенню адміністративного навантаження і підвищенню ефективності надання соціальних послуг.",
      "Прикладні підсистеми ЄІССС охоплюють всі аспекти соціального захисту, включаючи державні допомоги, соціальні послуги, пенсійне страхування, гуманітарну допомогу та підтримку внутрішньо переміщених осіб, малозабезпечених сімей і осіб з інвалідністю. Центр обробки даних побудований на базі рішень корпорації Oracle, що забезпечує надійну роботу і захист інформації. На даний момент ЄІССС активно розвивається, вже впроваджені основні підсистеми, які дозволяють автоматизувати багато задач, пов'язаних із соціальним захистом населення.",
    ],
    aboutProjectEn: [
      "A centralized platform designed to optimize the management of social programs and services in Ukraine. It unites various social institutions into a single information environment, ensuring centralized data processing and unification of technological processes. The system includes such subsystems as the Unified Social Register, the Electronic Budget, the Social Treasury, and others that help automate social support, reduce administrative burden, and improve the efficiency of social services.",
      "The UISSS application subsystems cover all aspects of social protection, including state benefits, social services, pension insurance, humanitarian assistance and support for internally displaced persons, low-income families and persons with disabilities. The data center is based on Oracle solutions, which ensures reliable operation and data protection. Currently, the UISSS is actively developing, and the main subsystems have already been implemented to automate many tasks related to social protection.",
    ],
    statistic: [],
    link: "https://www.ioc.gov.ua/eisss/",
    imgName: "EICC",
    video: "",
  },

  {
    id: 3,
    headerUa: "Автоматизована система реєстрації гуманітарної допомоги",
    headerEn: "Automated System of Humanitarian Aid Registration",
    shortHeaderUA: "АС ГД",
    shortHeaderEN: "AS HA",
    describeUa:
      "Система для прозорого управління гуманітарною допомогою в Україні, яка спрощує процеси реєстрації, митного контролю та розподілу допомоги, забезпечуючи ефективність і безпеку даних. ",
    describeEn:
      "A system for the transparent management of humanitarian aid in Ukraine that simplifies the processes of registration, customs control and distribution of aid, ensuring efficiency and data security. ",
    goalUa:
      "Забезпечити прозорість та ефективність розподілу гуманітарної допомоги під час воєнного стану через автоматизацію ввезення, обліку та контролю ресурсів.",
    goalEn:
      "Ensure transparency and efficiency in the distribution of humanitarian aid during martial law by automating the import, accounting and control of resources.",
    aboutProjectUa: [
      "Це цифрова платформа для управління гуманітарною допомогою в Україні. Вона забезпечує прозорість та ефективність розподілу допомоги під час воєнного стану. Основні функції включають автоматизовану реєстрацію отримувачів та використання електронних декларацій для швидкого проходження митного контролю. Платформа сприяє спрощенню ввезення та оформлення гуманітарних вантажів, а також контролює їх цільове використання. АС ГД створює прозору систему обліку та звітності, забезпечуючи захист персональних даних користувачів. ",
      "Вона призначена для волонтерів, державних та міжнародних організацій, а також отримувачів допомоги. Система інтегрується з іншими державними системами для точного обліку та контролю допомоги. Вона підтримує інтуїтивно зрозумілий інтерфейс. Служба контакт центру надає консультації користувачам 24/7. ",
    ],
    aboutProjectEn: [
      "It is a digital platform for managing humanitarian aid in Ukraine. It ensures transparency and efficiency of aid distribution during martial law. Key features include automated registration of recipients and the use of electronic declarations for fast customs clearance. The platform facilitates the importation and clearance of humanitarian goods and controls their intended use. The AS GD creates a transparent accounting and reporting system, ensuring the protection of users' personal data.",
      "It is designed for volunteers, government and international organizations, as well as aid recipients. The system integrates with other government systems for accurate accounting and control of aid. It supports an intuitive interface. The contact center service provides consultations to users 24/7. ",
    ],
    statistic: [
      {
        number: "9 895",
        secondTextUa: "Отримувачів в системі",
        secondTextEn: "Recipients in the system",
      },
      {
        number: "60 328",
        secondTextUa: "Зареєстрованих вантажів",
        secondTextEn: "Registered cargoes",
      },
      {
        number: "52 665",
        secondTextUa: "Заповнених декларацій",
        secondTextEn: "Completed declarations",
      },
    ],
    asOfDate: "01.07.2024",
    link: "https://good.gov.ua/",
    imgName: "АСГД",
    video: "",
  },
  {
    id: 4,
    headerUa: "Електронні реєстри",
    headerEn: "Electronic Registries",
    shortHeaderUA: "Електронні реєстри",
    shortHeaderEN: "Electronic Registries",
    describeUa:
      "Проєкт є ключовим інструментом для створення електронних реєстрів в Україні, заснований на сучасній платформі Дія.Engine.",
    describeEn:
      "The project is a key tool for creating electronic registries in Ukraine, based on the modern platform Diia.Engine. ",
    goalUa:
      "Забезпечити цифровізацію та  уніфікацію застарілих паперових та електронних реєстрів, спростити побудову  інформаційних обмінів та оптимізувати наявні бізнес-процеси.",
    goalEn:
      "Ensure the digitization and unification of outdated paper and electronic registers, simplify the construction of information exchanges, and optimize existing business processes.",
    aboutProjectUa: [
      "Це інноваційне рішення відповідає найвищим стандартам цифровізації та дозволяє ефективно управляти державними реєстрами, знижуючи ризики дублювання інформації, покращуючи доступність та надійність даних. Наша команда отримала ліцензію від Міністерства цифрової трансформації та готова допомогти вам створити сучасні реєстри.",
      "Створюючи реєстри разом з нами ви спрощуєте обмін інформацією між органами влади, забезпечуєте прозорість та зручність в управлінні та сприяєте швидкому прийняттю управлінських рішень на основі актуальних даних.",
    ],
    aboutProjectEn: [
      "This innovative solution meets the highest standards of digitalization and allows you to effectively manage state registries, reducing the risk of duplication of information, improving data availability and reliability. Our team has been licensed by the Ministry of Digital Transformation and is ready to help you create modern registries.",
      "By creating registries with us, you simplify the exchange of information between authorities, ensure transparency and ease of management, and facilitate quick decision-making based on up-to-date data.",
    ],
    statistic: [],
    link: "https://registers.ioc.gov.ua/",
    imgName: "Реєстри",
    video: "",
  },
  {
    id: 5,
    headerUa: "єДопомога",
    headerEn: "eDopomoga",
    shortHeaderUA: "єДопомога",
    shortHeaderEN: "eDopomoga",
    describeUa:
      "Проєкт створений з метою забезпечення швидкої адресної підтримки українським сім'ям, які стали жертвами воєнних конфліктів.",
    describeEn:
      "The project was created to provide rapid targeted support to Ukrainian families who have become victims of military conflicts.",
    goalUa:
      "Забезпечити базові потреби, такі як харчування, медичні засоби, гігієнічні товари та інше, для тих, хто втратив майно та стабільність через воєнні події в Україні.",
    goalEn:
      "To provide basic needs, such as food, medical supplies, hygiene products, etc. for those who have lost their property and stability due to the military events in Ukraine.",
    aboutProjectUa: [
      "Платформа дозволяє потребуючим залишити заявку про потреби, а благодійникам з усього світу відгукнутися на неї та допомогти харчовими продуктами, ліками, або паливом не виходячи з дому.  Головною цінністю проєкту є простота доступу до необхідних ресурсів через зручну платформу, яка забезпечує швидке реагування на потреби сімей. Також, вона дозволяє надати фітбєк благодійнику, у вигляді чека з переліком придбаних товарів, що підтверджує її прозорість.",
      "Платформа розроблена за участю Міністерства соціальної політики України, Мінцифри та міжнародних партнерів, що гарантує високий рівень ефективності та прозорості у процесі надання допомоги.",
      "Серед партнерських організацій, які приєдналися до платформи є: Mastercard, Fozzy Group, АТБ маркет, АНЦ, ОККО, SOCAR та інші.",
    ],
    aboutProjectEn: [
      "The platform allows people in need to submit a request for assistance, and philanthropists from around the world to respond and help with food, medicine, or fuel without leaving their homes.  The main value of the project is the ease of access to the necessary resources through a convenient platform that provides a quick response to the needs of families. It also allows you to provide a fitback to the benefactor in the form of a check with a list of purchased goods, which confirms its transparency.",
      "The platform was developed with the participation of the Ministry of Social Policy of Ukraine, the Ministry of Digital Transformation and international partners, which guarantees a high level of efficiency and transparency in the process of providing assistance.",
      "Among the partner organizations that have joined the platform are: Mastercard, Fozzy Group, ATБ Market, AНЦ, OKKO, SOCAR and others.",
    ],
    statistic: [
      {
        number: "34 064",
        secondTextUa: "Сім’ї, які отримали допомогу",
        secondTextEn: "Families who received help",
      },
      {
        number: "5 718",
        secondTextUa: "Благодійники платформи",
        secondTextEn: "Benefactors of the platform",
      },
      {
        number: "12 323 500",
        secondTextUa: "Придбано сертифікатів на суму",
        secondTextEn: "Certificates for the amount of",
      },
      {
        number: "50",
        secondTextUa: "Країн, де користуються платформою",
        secondTextEn: "Countries where the platform is used",
      },
    ],
    asOfDate: "01.07.2024",
    link: "https://social.edopomoga.gov.ua/uk/",
    imgName: "єДопомога",
    video: "/video",
  },
];

const projectsSlice = createSlice({
  name: "projects",
  initialState: projectsInitialState,
  reducers: {},
});

export const projectsReducer = projectsSlice.reducer;
